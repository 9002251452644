import React from "react"

import { graphql } from "gatsby"

import PropTypes from "prop-types"

import SEO from "../components/seo"
import RenderBlocks from "../utils/render-blocks"

const PageTemplate = ({ data }) => {
  const { page } = data.saleor
  const sections = page.content && JSON.parse(page.content)

  return (
    <>
      <SEO
        title={page.seoTitle}
        description={page.seoDescription}
        path={`/${page.slug}/`}
      />
      {sections && sections.length > 0 ? (
        sections.map((section) => RenderBlocks(section))
      ) : (
        <div>
          <div className="no-content">
            No Section has been added for this page yet
          </div>
        </div>
      )}
    </>
  )
}

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    saleor {
      page(slug: $slug) {
        id
        title
        slug
        seoTitle
        seoDescription
        content
        metadata {
          key
          value
        }
      }
    }
  }
`

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PageTemplate
